// src/utils/api.js
import axios from 'axios';
import { getToken } from './auth';

const API_URL = process.env.REACT_APP_API_URL || 'https://quizbe.kol.tel';

const api = axios.create({
  baseURL: API_URL,
  withCredentials: true // Important for CORS with credentials
});

api.interceptors.request.use((config) => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error("API Error:", error.response || error);
    return Promise.reject(error);
  }
);

export const login = (email, password) => api.post('/api/token', { username: email, password });
export const register = (userData) => api.post('/api/register', userData, { maxRedirects: 5 });
export const getProjects = () => api.get('/api/projects');
export const createProject = (projectData) => api.post('/api/projects', projectData);
export const deleteProject = (projectId) => api.delete(`/api/projects/${projectId}`);
export const uploadDocument = (projectId, file) => {
  const formData = new FormData();
  formData.append('file', file);
  return api.post(`/api/projects/${projectId}/upload-document`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};
export const createQuiz = (projectId, quizData) => api.post(`/api/projects/${projectId}/quizzes`, quizData);
export const startQuiz = (quizId) => api.get(`/api/quizzes/${quizId}/start`);
export const submitQuiz = (quizId, answers) => api.post(`/api/quizzes/${quizId}/submit`, answers);
export const getQuizResult = (quizId) => api.get(`/api/quizzes/${quizId}/result`);
export const getProjectResults = (projectId) => api.get(`/api/projects/${projectId}/results`);
export const getAvailableQuizzes = () => api.get('/api/quizzes/available');
export const getQuizResults = () => api.get('/api/quiz-results');

export default api;