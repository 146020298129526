// src/components/Header.js
import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Button, styled } from '@mui/material';
import { isAuthenticated, isAdmin, logout } from '../utils/auth';

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

const Logo = styled(Typography)(({ theme }) => ({
  textDecoration: 'none',
  color: 'inherit',
}));

const NavButtons = styled('div')(({ theme }) => ({
  '& > *': {
    margin: theme.spacing(0, 1),
  },
}));

function Header() {
  const navigate = useNavigate();
  const authenticated = isAuthenticated();
  const admin = isAdmin();

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  return (
    <AppBar position="static">
      <StyledToolbar>
        <Logo variant="h6" component={RouterLink} to="/">
          QuizAI
        </Logo>
        <NavButtons>
          {authenticated ? (
            <>
              <Button color="inherit" component={RouterLink} to="/dashboard">
                Dashboard
              </Button>
              {admin && (
                <Button color="inherit" component={RouterLink} to="/admin">
                  Admin
                </Button>
              )}
              <Button color="inherit" onClick={handleLogout}>
                Logout
              </Button>
            </>
          ) : (
            <>
              <Button color="inherit" component={RouterLink} to="/login">
                Login
              </Button>
              <Button color="inherit" component={RouterLink} to="/register">
                Register
              </Button>
            </>
          )}
        </NavButtons>
      </StyledToolbar>
    </AppBar>
  );
}

export default Header;