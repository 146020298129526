// src/utils/auth.js
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;
const TOKEN_KEY = 'quizai_auth_token';
const USER_KEY = 'quizai_user';

export const login = async (email, password) => {
  const response = await axios.post(`${API_URL}/token`, { username: email, password });
  const { access_token, token_type, user } = response.data;
  localStorage.setItem(TOKEN_KEY, `${token_type} ${access_token}`);
  localStorage.setItem(USER_KEY, JSON.stringify(user));
  return user;
};

export const register = async (userData) => {
  const response = await axios.post(`${API_URL}/register`, userData);
  return response.data;
};

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(USER_KEY);
};

export const isAuthenticated = () => {
  return localStorage.getItem(TOKEN_KEY) !== null;
};

export const getToken = () => {
  return localStorage.getItem(TOKEN_KEY);
};

export const getUser = () => {
  const userStr = localStorage.getItem(USER_KEY);
  if (userStr) return JSON.parse(userStr);
  return null;
};

export const isAdmin = () => {
  const user = getUser();
  return user && user.is_admin;
};

export const updateUser = (user) => {
  localStorage.setItem(USER_KEY, JSON.stringify(user));
};

export const getUserId = () => {
  const user = getUser();
  return user ? user.id : null;
};