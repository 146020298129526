// src/components/Footer.js
import React from 'react';
import { Typography, Link, Container, styled } from '@mui/material';

const FooterContainer = styled('footer')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(6, 0),
  marginTop: 'auto',
}));

function Footer() {
  return (
    <FooterContainer>
      <Container maxWidth="lg">
        <Typography variant="body2" color="textSecondary" align="center">
          {'Copyright © '}
          <Link color="inherit" href="https://yourwebsite.com/">
            QuizAI
          </Link>{' '}
          {new Date().getFullYear()}
          {'.'}
        </Typography>
      </Container>
    </FooterContainer>
  );
}

export default Footer;