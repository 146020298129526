// src/pages/QuizCreate.js
import React from 'react';
import { Typography, Container } from '@mui/material';

function QuizCreate() {
  return (
    <Container>
      <Typography variant="h4">Create Quiz</Typography>
      <Typography>This is a placeholder for the quiz creation page.</Typography>
    </Container>
  );
}

export default QuizCreate;