// src/pages/ProjectCreate.js
import React, { useState } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { createProject } from '../utils/api';
import { 
  TextField, 
  Button, 
  Typography, 
  Container, 
  Box,
  Alert
} from '@mui/material';

function ProjectCreate() {
  const [name, setName] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { user } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');

    if (name.trim().length < 3) {
      setError('Project name must be at least 3 characters long');
      setIsLoading(false);
      return;
    }

    try {
      await createProject({ name });
      navigate('/dashboard');
    } catch (err) {
      setError('Failed to create project. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  if (!user) {
    return <Navigate to="/login" />;
  }

  return (
    <Container maxWidth="sm">
      <Box my={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          Create New Project
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Project Name"
            variant="outlined"
            fullWidth
            margin="normal"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            error={!!error}
            helperText={error}
          />
          <Button 
            type="submit" 
            variant="contained" 
            color="primary" 
            disabled={isLoading}
            fullWidth
            size="large"
            style={{ marginTop: '20px' }}
          >
            {isLoading ? 'Creating...' : 'Create Project'}
          </Button>
        </form>
        {error && (
          <Alert severity="error" style={{ marginTop: '20px' }}>
            {error}
          </Alert>
        )}
      </Box>
    </Container>
  );
}

export default ProjectCreate;