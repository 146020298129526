// src/pages/StudentDashboard.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Container, 
  Typography, 
  Button, 
  List, 
  ListItem, 
  ListItemText, 
  ListItemSecondaryAction,
  Divider
} from '@mui/material';
import { getAvailableQuizzes, getQuizResults } from '../utils/api';

function StudentDashboard() {
  const [availableQuizzes, setAvailableQuizzes] = useState([]);
  const [quizResults, setQuizResults] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchAvailableQuizzes();
    fetchQuizResults();
  }, []);

  const fetchAvailableQuizzes = async () => {
    try {
      const response = await getAvailableQuizzes();
      setAvailableQuizzes(response.data);
    } catch (error) {
      console.error('Failed to fetch available quizzes:', error);
    }
  };

  const fetchQuizResults = async () => {
    try {
      const response = await getQuizResults();
      setQuizResults(response.data);
    } catch (error) {
      console.error('Failed to fetch quiz results:', error);
    }
  };

  return (
    <Container maxWidth="md">
      <Typography variant="h4" component="h1" gutterBottom>
        Student Dashboard
      </Typography>
      <Typography variant="h5" component="h2" gutterBottom>
        Available Quizzes
      </Typography>
      <List>
        {availableQuizzes.map((quiz) => (
          <ListItem key={quiz.id}>
            <ListItemText primary={quiz.title} secondary={`Duration: ${quiz.duration} minutes`} />
            <ListItemSecondaryAction>
              <Button
                variant="contained"
                color="primary"
                onClick={() => navigate(`/quizzes/take/${quiz.id}`)}
              >
                Take Quiz
              </Button>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
      <Divider style={{ margin: '20px 0' }} />
      <Typography variant="h5" component="h2" gutterBottom>
        Your Quiz Results
      </Typography>
      <List>
        {quizResults.map((result) => (
          <ListItem key={result.id}>
            <ListItemText 
              primary={result.quiz_title} 
              secondary={`Score: ${result.score}%, Completed: ${new Date(result.completed_at).toLocaleString()}`} 
            />
            <ListItemSecondaryAction>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => navigate(`/quizzes/result/${result.id}`)}
              >
                View Details
              </Button>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </Container>
  );
}

export default StudentDashboard;