// src/pages/ProjectDetail.js
import React from 'react';
import { Typography, Container } from '@mui/material';

function ProjectDetail() {
  return (
    <Container>
      <Typography variant="h4">Project Detail</Typography>
      <Typography>This is a placeholder for the project detail page.</Typography>
    </Container>
  );
}

export default ProjectDetail;