// src/pages/QuizResult.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Typography, Paper, List, ListItem, ListItemText, CircularProgress, styled } from '@mui/material';
import { getQuizResult } from '../utils/api';

const ResultContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(4),
}));

const ScorePaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(4),
  textAlign: 'center',
}));

function QuizResult() {
  const { resultId } = useParams();
  const [result, setResult] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchResult = async () => {
      try {
        const response = await getQuizResult(resultId);
        setResult(response.data);
      } catch (err) {
        setError('Failed to fetch quiz result. Please try again.');
      }
    };
    fetchResult();
  }, [resultId]);

  if (!result) {
    return <CircularProgress />;
  }

  return (
    <ResultContainer>
      <Typography variant="h4" component="h1" gutterBottom>
        Quiz Result
      </Typography>
      <ScorePaper elevation={3}>
        <Typography variant="h5" gutterBottom>
          Your Score
        </Typography>
        <Typography variant="h3">
          {result.score}%
        </Typography>
      </ScorePaper>
      <List>
        {result.questions && result.questions.map((question, index) => (
          <ListItem key={index}>
            <ListItemText
              primary={`Question ${index + 1}: ${question.text}`}
              secondary={`Your answer: ${question.user_answer}, Correct answer: ${question.correct_answer}`}
            />
          </ListItem>
        ))}
      </List>
      {error && (
        <Typography color="error" align="center">
          {error}
        </Typography>
      )}
    </ResultContainer>
  );
}

export default QuizResult;