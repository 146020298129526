// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './context/AuthContext';
import Header from './components/Header';
import Footer from './components/Footer';
import Login from './pages/Login';
import Register from './pages/Register';
import AdminDashboard from './pages/AdminDashboard';
import StudentDashboard from './pages/StudentDashboard';
import ProjectCreate from './pages/ProjectCreate';
import ProjectDetail from './pages/ProjectDetail';
import QuizCreate from './pages/QuizCreate';
import QuizTake from './pages/QuizTake';
import QuizResult from './pages/QuizResult';

const PrivateRoute = ({ children }) => {
  const { user } = useAuth();
  return user ? children : <Navigate to="/login" />;
};

function App() {
  return (
    <AuthProvider>
      <Router>
        <Header />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/admin" element={<PrivateRoute><AdminDashboard /></PrivateRoute>} />
          <Route path="/dashboard" element={<PrivateRoute><StudentDashboard /></PrivateRoute>} />
          <Route path="/projects/create" element={<PrivateRoute><ProjectCreate /></PrivateRoute>} />
          <Route path="/projects/:id" element={<PrivateRoute><ProjectDetail /></PrivateRoute>} />
          <Route path="/quizzes/create" element={<PrivateRoute><QuizCreate /></PrivateRoute>} />
          <Route path="/quizzes/take/:id" element={<PrivateRoute><QuizTake /></PrivateRoute>} />
          <Route path="/quizzes/result/:id" element={<PrivateRoute><QuizResult /></PrivateRoute>} />
          <Route path="/" element={<Navigate to="/login" />} />
        </Routes>
        <Footer />
      </Router>
    </AuthProvider>
  );
}

export default App;